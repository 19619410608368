<template>
  <div class="page_bg" style="margin:0;">
    <!-- 顶部返回 -->
    <!-- <div class="back_top_name flex-r-sb-c" style=" padding: 0.32rem;background: #ffffff;font-size: 0.48rem;">
      <van-icon name="arrow-left" @click="backPath" />
      <div class="top_name">{{ name }}</div>
      <div class="top_icon" />
    </div> -->
    <div class="search_top  flex-c-s-c" style="background: rgb(255, 255, 255);">
      <div class="ser_search  flex-r-sb-c">
        <div />
        <van-icon name="arrow-left" size="0.5rem" @click="backPath" />
        <van-search v-model="searchValue" class="classify_ser" placeholder="请输入商品名称" shape="round" @search="inputSer"
                    @focus="onFocus"
        >
          <template #right-icon>
            <van-button round color="#0767AB" size="small" style="width:1.49rem;height:0.69rem;margin-top:-0.1rem"
                        @click="inputSer"
            >搜索</van-button>
          </template>
        </van-search>
        <div />
      </div>
    </div>
    <!-- 顶部过滤 -->
    <serTopFilter ref="serTopFilter" :type="3" :tagid="$route.query.tagId" @changeFilter="changeFilter"
                  @changeFiltercategory="changeFiltercategory" @changeFilterbrand="changeFilterbrand"
    />
    <!-- 商品列表区域  -->
    <div class="pro_list">
      <MyscrollList ref="myList01" :loading="listLoading" :list="proList" @myload="proload">
        <template #default="slotProps">
          <proCard01 :info="slotProps.item" @addCart="toCart" />
        </template>
      </MyscrollList>
    </div>

    <!-- spu -->
    <spuDrawer v-if="showSpu" ref="spuDrawer" source="list" :info="proDetailInfo" :pro-id="pid"
               :spu-level01-id="firstLevelTagId" :spu-level02-id="secondLevelTagId" @loadPro="loadPro"
               @closespu="closePopup"
    />
  </div>
</template>

<script>
import { recommendProductList } from '@/api/home'
import MyscrollList from '@/components/MyscrollList'
import proCard01 from '@/components/pro_card_01'
import serTopFilter from '@/components/ser_top_filter'
import { ProductListspu } from '@/api/cart'
import spuDrawer from '@/components/spuDrawer'
export default {
  components: { MyscrollList, proCard01, serTopFilter, spuDrawer },
  data() {
    return {
      searchValue: '',
      name: '',
      // 商品列表
      proSearch: { page: 1, limit: 10, proBrand: '', tagId: '' },
      proList: [],
      listLoading: false,

      showSpu: false,
      pid: 0,
      proDetailInfo: {},
      firstLevelTagId: null,
      secondLevelTagId: null
    }
  },
  created() {
    this.proSearch.tagId = this.$route.query.tagId
    this.getProList()
    // 动态修改微信网页标题
    this.changeTitleClick(this.$route.query.tagId)
  },
  activated() {
    console.log('keepalive 进入tagIdProList')
    this.changeTitleClick(this.$route.query.tagId)
    if (sessionStorage.getItem('listJump')) {
      window.scrollTo({
        top: 0, // 滚动到顶部
        behavior: 'smooth' // 平滑滚动
      })
      sessionStorage.removeItem('listJump')
      this.proSearch.proBrand = this.$route.query.name
      this.getProList()
    }
  },
  deactivated() {
    this.$refs.serTopFilter.closePullBox()
    console.log('keepalive 离开tagIdProList')
  },
  methods: {
    // 动态修改微信网页标题
    changeTitleClick(tagid) {
      console.log('this.name', this.name, tagid)
      document.setTitle = function (t) {
        document.title = t
        var i = document.createElement('iframe')
        i.src = './favicon.ico'
        i.style.display = 'none'
        i.onload = function () {
          setTimeout(function () {
            i.remove()
          }, 9)
        }
        document.body.appendChild(i)
      }
      setTimeout(function () {
        var name = ''
        if (tagid === '275') name = '严选-零售创收'
        if (tagid === '281') name = '严选-实惠自用'
        if (tagid === '282') name = '超值通货'
        if (tagid === '349') name = 'VIP快速升级通道'
        if (tagid === '358') name = '门店创收商品'
        if (tagid === '332') name = '贝思倍健商品'
        document.setTitle(name)
        window.document.title = name
        this.$route.meta.title = name
      }, 1)
    },
    backPath() { window.history.back() },
    getProList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      recommendProductList(this.proSearch).then(res => {
        this.$toast.clear()
        this.$refs.myList01.load_loading(false)
        this.proList = this.proList.concat(res.data.list)
        if (parseInt(res.data.list) === 0) { this.$refs.myList01.load_empty('list', true) } else {
          if (res.data.list.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.page = this.proSearch.page + 1
        this.getProList()
        // 加载状态结束
      }, 50)()
    },

    // 顶部搜索
    changeFilter(e) {
      console.log(e)
      this.proSearch.page = 1
      if (e === 1 || e === 41) {
        this.proSearch.orderType = ''
        this.proSearch.orderWay = ''
        this.proSearch.tagId = this.$route.query.tagId; this.proSearch.proBrand = ''; this.proSearch.categoryId = ''
      }
      if (e === 2) {
        this.issaleNum = !this.issaleNum
        this.proSearch.orderType = 'SALE_NUM'
        this.proSearch.orderWay = this.issaleNum ? 'DESC' : 'ASC'
        this.proSearch.tagId = this.$route.query.tagId; this.proSearch.proBrand = ''; this.proSearch.categoryId = ''
      }
      if (e === 3) {
        this.isprice = !this.isprice
        this.proSearch.orderType = 'PRICE'
        this.proSearch.orderWay = this.isprice ? 'DESC' : 'ASC'
        this.proSearch.tagId = this.$route.query.tagId; this.proSearch.proBrand = ''; this.proSearch.categoryId = ''
      }
      this.proList = []
      this.getProList()
    },
    changeFiltercategory(id) {
      this.proSearch.page = 1
      this.proSearch.orderType = ''; this.proSearch.orderWay = ''; this.proSearch.proBrand = ''
      this.proSearch.tagId = this.$route.query.tagId; this.proSearch.categoryId = id
      this.$nextTick(() => {
        this.proList = []
        this.getProList()
      })
    },
    changeFilterbrand(brand) {
      this.proSearch.page = 1
      this.proSearch.orderType = ''; this.proSearch.orderWay = ''; this.proSearch.proBrand = brand
      this.proSearch.tagId = this.$route.query.tagId; this.proSearch.categoryId = ''
      this.$nextTick(() => {
        this.proList = []
        this.getProList()
      })
    },
    // 获焦事件
    onFocus() {
      this.$refs.serTopFilter.closePullBox()
      this.$refs.myList01.load_status_clear()
      this.searchValue = ''
      this.proList = []
      this.proSearch.page = 1
    },
    inputSer() {
      this.$refs.serTopFilter.closePullBox()
      this.proList = []
      this.proSearch.page = 1
      this.proSearch.proName = this.searchValue
      this.getProList()
    },
    loadPro(e) { console.log(e) },
    // 加入购物车
    toCart(item) {
      console.log(item)
      this.pid = item.productId
      this.proDetailInfo = item
      this.showSpu = true
      ProductListspu(item.productId).then(res => {
        this.firstLevelTagId = res.data.length > 0 ? res.data[0].firstLevelTagId : null
        this.secondLevelTagId = res.data.length > 0 ? res.data[0].secondLevelTagId : null
        this.$nextTick(() => {
          this.$refs.spuDrawer.changeShow(true)
        })
      })
    },
    addspu(item) {
      console.log('addspu', item)
      this.pid = item.productId
      this.proDetailInfo = item
      this.showSpu = true
      ProductListspu(item.productId).then(res => {
        this.firstLevelTagId = res.data.length > 0 ? res.data[0].firstLevelTagId : null
        this.secondLevelTagId = res.data.length > 0 ? res.data[0].secondLevelTagId : null
        console.log('addspu', this.firstLevelTagId, this.secondLevelTagId)
        this.$nextTick(() => {
          this.$refs.spuDrawer.changeShow(true)
        })
      })
    },
    closePopup() {
      console.log('closePopup')
      this.firstLevelTagId = null
      this.secondLevelTagId = null
      this.showSpu = false
    }
  }
}
</script>

<style>
</style>
